import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const containerClass = classNames({
  'ai-strengths-title-container': true,
})

class Title extends Component {

  render() {
    const {
      id,
      text,
    } = this.props

    return (
      <h3 id={id} className={containerClass}>
        {text}
      </h3>
    )
  }
}

Title.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

Title.defaultProps = {
  id: undefined,
  text: undefined,
}

export default Title
