import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../../../../../components/Image'

import backgroundPicture from '../../../../../img/artworks/icon_aiPower_empty.svg'

const containerClass = classNames({
  'ai-strengths-logo-container': true,
})

class Logo extends Component {

  render() {
    const {
      picture,
      alt,
    } = this.props

    return (
      <div className={containerClass}>
        <Image
          src={backgroundPicture}
          alt={'grid'}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        />
        <Image
          src={picture}
          alt={alt}
          style={{
            position: 'absolute',
            top: 28,
            left: 28,
          }}
        />
      </div>
    )
  }
}

Logo.propTypes = {
  picture: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

Logo.defaultProps = {
  picture: undefined,
  alt: undefined,
}

export default Logo
