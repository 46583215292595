import './style.css'
import React, { Component } from 'react'
import { Col } from 'react-grid-system'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const containerClass = classNames({
  'ai-strengths-content-col-container': true,
})

class ContentCol extends Component {

  render() {
    const {
      xs,
      md,
      mdOffset,
      children,
    } = this.props

    return (
      <Col
        xs={xs}
        md={md}
        offset={{ md: mdOffset }}
      >
        <div className={containerClass}>
          {children}
        </div>
      </Col>
    )
  }
}

ContentCol.propTypes = {
  xs: PropTypes.number,
  md: PropTypes.number,
  mdOffset: PropTypes.number,
  children: PropTypes.node,
}

ContentCol.defaultProps = {
  xs: 12,
  md: 4,
  mdOffset: 0,
  children: 0,
}

export default ContentCol
