import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Hidden, Visible, ScreenClassRender } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Space from '../../../components/Space'
import ArtworkCol from './components/ArtworkCol'
import ContentCol from './components/ContentCol'
import Logo from './components/Logo'
import Title from './components/Title'
import Description from './components/Description'

import activityIcon from '../../../img/icons/icon_activity.svg'
import layersIcon from '../../../img/icons/icon_layers.svg'
import cpuIcon from '../../../img/icons/icon_cpu.svg'
import shareIcon from '../../../img/icons/icon_share.svg'

const containerClass = classNames({
  'search-expertise-container': true,
})

class Strengths extends Component {

  render() {
    return (
      <div className={containerClass}>
        <Container>
          {this.renderBeAutonomous()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderMakeItEasy()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderScalable()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderLowLevelExpertise()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
        </Container>
      </div>
    )
  }

  renderBeAutonomous = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(data.strength1Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.searchExpertise.beAutonomous.alt' }), 7, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={1}
        >
          <Logo
            picture={shareIcon}
            alt={formatMessage({ id: 'enterprise.searchExpertise.beAutonomous.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.searchExpertise.beAutonomous.id' })}
            text={formatMessage({ id: 'enterprise.searchExpertise.beAutonomous.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.searchExpertise.beAutonomous.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(data.strength1Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.searchExpertise.beAutonomous.alt' }), 7, 0)}
        </Visible>
      </Row>
    )
  }

  renderMakeItEasy = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={cpuIcon}
            alt={formatMessage({ id: 'enterprise.searchExpertise.makeItEasy.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.searchExpertise.makeItEasy.id' })}
            text={formatMessage({ id: 'enterprise.searchExpertise.makeItEasy.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.searchExpertise.makeItEasy.description' })} />
        </ContentCol>
        {this.renderArtwork(data.strength2Picture.childImageSharp.fluid, 480, formatMessage({ id: 'enterprise.searchExpertise.makeItEasy.alt' }), 6, 2)}
      </Row>
    )
  }

  renderScalable = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(data.strength3Picture.childImageSharp.fluid, 433, formatMessage({ id: 'enterprise.searchExpertise.scalable.alt' }), 6, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={2}
        >
          <Logo
            picture={layersIcon}
            alt={formatMessage({ id: 'enterprise.searchExpertise.scalable.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.searchExpertise.scalable.id' })}
            text={formatMessage({ id: 'enterprise.searchExpertise.scalable.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.searchExpertise.scalable.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(data.strength3Picture.childImageSharp.fluid, 433, formatMessage({ id: 'enterprise.searchExpertise.scalable.alt' }), 6, 0)}
        </Visible>
      </Row>
    )
  }

  renderLowLevelExpertise = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={activityIcon}
            alt={formatMessage({ id: 'enterprise.searchExpertise.lowLevelExpertise.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.searchExpertise.lowLevelExpertise.id' })}
            text={formatMessage({ id: 'enterprise.searchExpertise.lowLevelExpertise.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.searchExpertise.lowLevelExpertise.description' })} />
        </ContentCol>
        {this.renderArtwork(data.strength4Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.searchExpertise.lowLevelExpertise.alt' }), 7, 1)}
      </Row>
    )
  }

  renderArtwork = (src, height, alt, md = 6, mdOffset = 0) => {
    return (
      <ArtworkCol
        xs={12}
        md={md}
        mdOffset={mdOffset}
      >
        <Visible xs sm>
          <Space type="s" />
        </Visible>
        <GatsbyImage
          fluid={src}
          alt={alt}
          style={{
            height: 'auto',
            maxHeight: height,
            maxWidth: '100%',
          }}
        />
      </ArtworkCol>
    )
  }
}

export default injectIntl(Strengths)
