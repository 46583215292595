import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const containerClass = classNames({
  'ai-strengths-description-container': true,
})

class Description extends Component {

  render() {
    const {
      text,
    } = this.props

    return (
      <p className={containerClass}>
        {text}
      </p>
    )
  }
}

Description.propTypes = {
  text: PropTypes.string.isRequired,
}

Description.defaultProps = {
  text: undefined,
}

export default Description
