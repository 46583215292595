import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import { injectIntl } from 'react-intl'

import Button from '../../../components/Button'
import I18nLink from '../../../components/I18nLink'
import Logo from '../../../components/Logo'
import Space from '../../../components/Space'
import Item from '../../Navigation/components/Item'
import SubItem from '../../Navigation/components/SubItem'
import BurgerButton from '../../Navigation/components/BurgerButton'

const desktopContainerClass = classNames({
  'navigation-desktop-container': true,
})

const desktopLeftClass = classNames({
  'navigation-desktop-left': true,
})

const desktopRightClass = classNames({
  'navigation-desktop-right': true,
})

const navigationMobileContainerClass = classNames({
  'navigation-mobile-container': true,
})

const mobileContentClass = classNames({
  'navigation-mobile-content': true,
})

class Navigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      atTop: true,
      isMenuToggled: false,
      isTransparent: props.isTransparent,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  // Handlers
  handleScroll = () => {
    const { isTransparent } = this.props
    const { isMenuToggled } = this.state

    // Full browser support
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    const atTop = scrollPosition === 0

    this.setState({
      atTop: atTop,
      isTransparent: isTransparent && atTop && !isMenuToggled,
    })
  }

  handleBurgerButtonClick = () => {
    const { isMenuToggled, atTop } = this.state
    const { isTransparent } = this.props
    const shouldBecomeTransparent = isMenuToggled && isTransparent && atTop

    this.setState({
      isMenuToggled: !isMenuToggled,
      isTransparent: shouldBecomeTransparent,
    })
  }


  // Render
  render() {
    const {
      atTop,
      isMenuToggled,
      isTransparent,
    } = this.state
    const {
      children,
      isFixed,
    } = this.props

    const navigationContainerClass = classNames({
      'navigation-container': true,
      'navigation-container-maxed': isMenuToggled,
      'navigation-container-shadowed': !isMenuToggled && !atTop,
      'navigation-container-transparent': isTransparent,
      'navigation-container-fixed': isFixed,
    })

    return (
      <div className={navigationContainerClass}>
        <Hidden xs sm md>
          <Container >
            <Row>
              <Col xs={12}>
                {this.renderDesktop()}
              </Col>
            </Row>
          </Container>
        </Hidden>

        <Visible xs sm md>
          {this.renderMobile()}
        </Visible>

        {
          children &&
          <Hidden xs sm md>
            {children}
          </Hidden>
        }
      </div>
    )
  }

  renderDesktop = () => {
    return (
      <div className={desktopContainerClass}>
        <div className={desktopLeftClass}>
          {this.renderLogo()}
          {this.renderItem('solutions')}
          {this.renderItem('searchExpertise')}
          {this.renderItem('apiDocumentation')}
        </div>
        <div className={desktopRightClass}>
          {this.renderContactUsButton()}
        </div>
      </div>
    )
  }

  renderMobile = () => {
    const {
      isMenuToggled,
      isTransparent,
    } = this.state

    return (
      <Fragment>
        <div className={navigationMobileContainerClass}>
          {this.renderLogo()}
          <BurgerButton
            isTransparent={isTransparent}
            isToggled={isMenuToggled}
            onClick={this.handleBurgerButtonClick}
          />
        </div>
        {isMenuToggled && this.renderMobileMenu()}
      </Fragment>
    )
  }

  renderMobileMenu = () => {
    return (
      <div className={mobileContentClass}>
        {this.renderContactUsButton()}
        <Space type="xl" />
        {this.renderItem('forContentCreators')}
        <Space type="xl" />
        {this.renderItem('forKnowledgeManagers')}
        <Space type="s" />
        {this.renderItem('searchExpertise')}
        <Space type="s" />
        {this.renderItem('apiDocumentation')}
      </div>
    )
  }

  renderLogo = () => {
    const {
      isTransparent,
    } = this.state

    return (
      <I18nLink to="/enterprise">
        <Logo
          height={60}
          color={isTransparent ? 'white' : 'red'}
        />
      </I18nLink>
    )
  }

  renderItem = (type) => {
    const { isTransparent } = this.state
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    switch (type) {
      case 'solutions':
        return (
          <Item
            text={formatMessage({ id: 'enterpriseNavigation.links.solutions' })}
            isReversed={isTransparent}
          >
            <SubItem text={formatMessage({ id: 'enterpriseNavigation.links.contentCreators' })} uri='/enterprise/content-creators' />
            <Space type="xs" />
            <SubItem text={formatMessage({ id: 'enterpriseNavigation.links.knowledgeManagers' })} uri='/enterprise/knowledge-managers' />
          </Item>
        )
      case 'forContentCreators':
        return (<Item text={formatMessage({ id: 'enterpriseNavigation.links.contentCreators' })} uri='/enterprise/content-creators' isReversed={isTransparent} />)
      case 'forKnowledgeManagers':
        return (<Item text={formatMessage({ id: 'enterpriseNavigation.links.knowledgeManagers' })} uri='/enterprise/knowledge-managers' isReversed={isTransparent} />)
      case 'apiDocumentation':
        return (<Item text={formatMessage({ id: 'enterpriseNavigation.links.apiDocumentation' })} url={'https://wuha.gitbook.io/api'} isReversed={isTransparent} newTab={true} />)
      case 'searchExpertise':
        return (<Item text={formatMessage({ id: 'enterpriseNavigation.links.ourExpertise' })} uri={'/enterprise/search-expertise'} isReversed={isTransparent} />)
      default:
        return null
    }
  }

  renderContactUsButton = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <a href={'https://wuha.typeform.com/to/QpTXJG'} target="_blank" rel="noopener noreferrer">
        <Button
          text={formatMessage({ id: 'enterprise.navigation.button.contactUs' })}
          color="red"
          isShadowed
        />
      </a>
    )
  }
}

Navigation.propTypes = {
  isTransparent: PropTypes.bool,
  isFixed: PropTypes.bool,
  children: PropTypes.node,
  intl: PropTypes.object,
}

Navigation.defaultProps = {
  isTransparent: false,
  isFixed: false,
  children: undefined,
}

export default injectIntl(Navigation)
